import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 720.000000 545.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,545.000000) scale(0.100000,-0.100000)"
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd">
          <path d="M3626 4195 c-29 -30 -4 -52 66 -60 80 -9 162 -37 263 -88 97 -50 230
-178 288 -277 95 -164 120 -372 67 -575 -51 -199 -228 -399 -422 -476 -114
-46 -151 -53 -278 -53 -149 -1 -214 14 -347 79 -73 36 -110 63 -172 123 -129
126 -189 240 -219 418 -18 104 -35 130 -67 104 -45 -37 40 -324 133 -451 247
-335 687 -439 1042 -246 201 109 321 257 395 487 24 74 28 102 28 215 1 106
-3 145 -21 210 -67 236 -215 417 -427 519 -139 68 -298 102 -329 71z"/>
<path d="M3615 4050 c-9 -29 16 -50 59 -50 140 -1 343 -128 438 -274 83 -129
114 -324 74 -475 -54 -209 -226 -382 -431 -436 -75 -19 -233 -20 -308 0 -75
20 -180 74 -241 124 -54 46 -71 50 -86 22 -8 -17 -3 -26 36 -63 59 -55 85 -72
164 -109 173 -80 405 -81 565 -1 160 80 291 216 345 360 101 267 37 551 -167
743 -107 101 -239 162 -374 175 -64 6 -67 6 -74 -16z"/>
<path d="M3623 3929 c-16 -16 -17 -36 -2 -45 6 -3 44 -15 85 -25 126 -31 220
-96 285 -195 131 -201 100 -448 -75 -612 -72 -67 -160 -108 -260 -120 -65 -8
-90 -28 -67 -56 43 -51 275 32 380 136 250 248 214 637 -76 839 -83 58 -244
104 -270 78z"/>
<path d="M3490 3723 c-237 -84 -305 -385 -127 -564 67 -67 135 -94 237 -94 71
0 94 4 142 27 67 31 129 92 166 163 24 44 27 62 27 145 0 89 -2 99 -34 158
-38 70 -90 120 -160 153 -61 29 -187 36 -251 12z"/>
<path d="M3931 2108 c-5 -13 -25 -74 -46 -135 -20 -62 -40 -115 -44 -118 -10
-6 -103 244 -95 256 3 5 -23 9 -59 9 l-65 0 48 -117 c83 -206 125 -296 135
-295 6 0 30 57 55 126 24 69 47 126 50 126 3 0 33 -56 68 -125 38 -77 68 -125
77 -125 8 0 15 2 15 5 0 17 115 364 126 383 14 21 13 22 -15 22 -22 0 -29 -4
-25 -14 3 -8 -11 -68 -32 -133 l-37 -118 -31 65 c-17 36 -45 98 -63 138 -34
76 -48 87 -62 50z"/>
<path d="M4569 2117 c-52 -12 -90 -56 -96 -110 -5 -38 -1 -48 23 -76 16 -18
58 -46 94 -64 72 -36 86 -52 76 -90 -13 -55 -110 -56 -168 0 l-28 27 0 -32 c0
-38 21 -51 94 -59 64 -7 114 8 147 43 19 20 24 36 24 79 0 66 -17 88 -104 127
-91 41 -113 76 -71 118 30 30 73 25 115 -11 l35 -31 0 30 c0 17 -4 33 -9 37
-22 13 -99 20 -132 12z"/>
<path d="M1175 1915 l0 -205 56 0 55 0 0 97 c-1 132 11 133 95 14 35 -50 71
-93 79 -96 33 -13 140 -18 140 -6 0 6 -6 11 -14 11 -15 0 -32 19 -102 117 -47
68 -53 83 -28 83 25 0 74 56 74 85 0 78 -59 104 -230 105 l-125 0 0 -205z
m226 166 c18 -14 24 -29 24 -61 0 -58 -39 -92 -99 -88 l-41 3 -3 83 -3 82 49
0 c34 0 57 -6 73 -19z"/>
<path d="M1849 2106 c9 -10 11 -69 9 -205 l-3 -191 142 0 c130 0 143 2 153 19
5 11 10 27 10 36 0 14 -4 14 -26 -4 -22 -17 -41 -21 -100 -21 l-74 0 0 90 0
90 58 0 c32 0 63 -5 70 -12 9 -9 12 -5 12 21 0 29 -2 32 -22 24 -13 -4 -43 -8
-68 -8 l-45 0 -3 79 -3 78 75 -4 c55 -2 80 -8 95 -22 21 -19 21 -19 21 12 l0
32 -156 0 c-134 0 -155 -2 -145 -14z"/>
<path d="M2440 2099 c11 -20 11 -335 0 -369 -4 -11 4 -16 24 -18 16 -2 26 1
22 6 -10 17 -18 270 -9 287 8 13 33 -16 129 -144 73 -98 126 -160 137 -161 16
0 17 16 17 198 0 141 3 201 12 210 9 9 5 12 -17 12 l-30 0 5 -117 c3 -65 3
-122 -1 -126 -5 -7 -35 30 -113 138 -83 114 -71 105 -131 105 -53 0 -55 -1
-45 -21z"/>
<path d="M3055 1915 l0 -205 143 0 c142 0 142 0 154 25 15 33 5 41 -24 21 -15
-11 -45 -16 -95 -16 l-73 0 0 90 0 90 59 0 c33 0 63 -4 66 -10 12 -20 26 -9
23 18 -3 23 -7 26 -28 22 -14 -2 -45 -4 -70 -5 l-45 0 -3 78 -3 77 63 0 c43 0
75 -6 99 -19 l36 -19 0 29 0 29 -151 0 -151 0 0 -205z"/>
<path d="M4998 2108 c8 -8 12 -56 12 -151 0 -106 4 -147 16 -172 40 -84 215
-101 288 -29 26 26 26 28 26 176 0 84 5 158 10 169 10 17 7 19 -20 19 -27 0
-30 -2 -20 -19 11 -22 13 -214 2 -275 -9 -53 -45 -78 -110 -79 -84 -1 -87 6
-87 205 l0 168 -64 0 c-51 0 -62 -3 -53 -12z"/>
<path d="M5655 1915 l0 -208 135 5 c180 7 237 31 247 107 5 34 2 43 -22 65
-16 15 -46 31 -67 38 l-40 12 36 18 c20 10 43 29 52 43 14 22 14 27 -1 58 -24
51 -53 60 -205 65 l-135 4 0 -207z m226 166 c17 -13 23 -29 23 -56 0 -52 -38
-85 -100 -85 l-44 0 0 80 0 80 49 0 c33 0 56 -6 72 -19z m24 -191 c34 -32 35
-91 0 -125 -20 -21 -34 -25 -85 -25 l-60 0 0 91 0 91 60 -4 c45 -3 66 -10 85
-28z"/>
</g>
      </svg>


    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
